import { useState } from 'react';
import { Button,Typography, notification, Modal, Select, Space, Input } from 'antd';
import { useQuery } from '@apollo/client';

import { RVP_Final_Program, RVP_Final_Stratum, RvpFinalStratumInput } from '../../graphql/types';
import { GET_RVP_FINAL_PROGRAMS_STRATA } from '../../graphql/queries';

//import { RVP_Draft_Program } from '../../graphql/types';
import { GET_RVP_DRAFT_PROGRAMS } from '../../graphql/queries';

import {
  useCopyRvpFinalToDraftProgramMutation,
  useRetireRvpFinalProgramMutation

} from '../../hooks/useMutations';

import RvpFinalProgramTable from '../../components/RvpFinalProgramTable';
import config from '../../config'

import RvpFinalProgramFormModal from '../../components/RvpFinalProgramFormModal';
import RvpFinalStratumFormModal from '../../components/RvpFinalStratumFormModal';


function RvpFinal() {
  // @ts-ignore
  const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
  const userEmail = idTokenClaims.preferred_username;

  const [showLatestYear, setShowLatestYear] = useState(true);

  const [copyingToRvpDraftProgram, setCopyingToRvpDraftProgram] = useState<RVP_Final_Program | undefined>(undefined);
  const [copyingConfirmationVisible, setCopyingConfirmationVisible] = useState(false);

  const [retiring, setRetiring] = useState<RVP_Final_Program | undefined>(undefined);
  const [retiringConfirmationVisible, setRetiringConfirmationVisible] = useState(false);
  
  const [copyRvpFinalToDraft] = useCopyRvpFinalToDraftProgramMutation();
  const [retire] = useRetireRvpFinalProgramMutation();

  const [readingRvpFinalProgram, setReadingRvpFinalProgram] = useState<RVP_Final_Program | undefined>(undefined);
  const [visibleProgramForm, setVisibleProgramForm] = useState(false);
  const showModal = (readingRvpFinalProgram?: RVP_Final_Program) => {
    setReadingRvpFinalProgram(readingRvpFinalProgram);
    setVisibleProgramForm(true);
  };
  const [visibleStratumForm, setVisibleStratumForm] = useState(false);
  const [readingRvpFinalStratum, setReadingRvpFinalStratum] = useState<RvpFinalStratumInput>();
  const showModalStratum = (readingRvpFinalStratum: RVP_Final_Stratum) => {
    setReadingRvpFinalStratum(readingRvpFinalStratum);
    setVisibleStratumForm(true);
  };

  // const currentYear = new Date().getFullYear();
  
  // const fisicalYearOptions = Array.from({ length: 8 }, (_, index) => ({
  //   value: (currentYear - 4 + index).toString() + "-04-01",
  //   label: (currentYear - 4 + index).toString() + "-04-01"
  // }));
  const currentYear = new Date().getFullYear();
  const startYear = Math.max(2021, currentYear - 4); // Ensure startYear is no earlier than 2021

  const fisicalYearOptions = Array.from({ length: 8 }, (_, index) => ({
    value: (startYear + index).toString() + "-04-01",
    label: (startYear + index).toString() + "-04-01"
  }));

  const [inputFiscalYearValue, setInputFiscalYearValue] = useState('');
  const confirmCopy = async () => {
    try {
      if (inputFiscalYearValue ) {
        const { data } = await copyRvpFinalToDraft({
          variables: {
            idSk: copyingToRvpDraftProgram?.idSk || "",
            fiscalYearStartDate: inputFiscalYearValue,
            rowCreatedBy: userEmail
          },
        });
        if (data?.copyRvpFinalToDraftProgram) {
          notification.success({ message: "Copying successful!" });
        }
        else {
          notification.error({ message: "The program was not copied!" });
        }
      }
      else {
        notification.error({ message: "The required information must be filled!" });
      }
    } catch (error) {
      notification.error({ message: "The program was not copied!" });
    }
    setCopyingConfirmationVisible(false);
  };

  const [inputCommentValue, setInputCommentValue] = useState('');
  const confirmRetire = async () => {
    try {
      if (inputCommentValue ) {
        const { data } = await retire({
          variables: {
            idSk: retiring?.idSk || "",
            comment: inputCommentValue,
            rowModifiedBy: userEmail
          },
        });
        if (data?.retireRvpFinalProgram) {
          notification.success({ message: "Retiring successful!" });
          refetchRVPFinal();
        }
        else {
          notification.error({ message: "The program was not soft deleted!" });
        }
      }
      else {
        notification.error({ message: "The required information must be filled!" });
      }
    } catch (error) {
      notification.error({ message: "The program was not soft deleted!" });
    }
    setRetiringConfirmationVisible(false);
  };


  const { loading: loadingRvpFinal, error: errorRvpFinal, data: dataRvpFinal, refetch: refetchRVPFinal } = useQuery(GET_RVP_FINAL_PROGRAMS_STRATA);
  const { loading: loadingRvpDraft, error: errorRvpDraft, data: dataRvpDraft, refetch: refetchRvpDraft } = useQuery(GET_RVP_DRAFT_PROGRAMS);

  if (loadingRvpFinal || loadingRvpDraft) {
    return <div>Loading...</div>;
  }

  if (errorRvpFinal) {
    return <div>Error RVP Final: {errorRvpFinal.message}</div>;
  }

  if (errorRvpDraft) {
    return <div>Error RVP Draft: {errorRvpDraft.message}</div>;
  }

  const rvpFinalPrograms = dataRvpFinal?.rvpFinalProgramsStrata ?? [];
  //const rvpDraftPrograms = dataRvpDraft?.rvpDraftPrograms ?? [];
  // // Function to check for duplicated names in the draft
  // const checkForDuplicatedName = (programName: string): boolean => {
  //   const isDuplicated = rvpDraftPrograms.some((program: { program: string; }) => program.program === programName);
  //   return isDuplicated;
  // };

  const handleCopyToDraft = async (currentRvpFinalProgram: RVP_Final_Program) => {
    const programName = currentRvpFinalProgram.program;
  
    try {
      // Explicitly refetch the draft data from the server
      const { data: freshDraftData } = await refetchRvpDraft();
  
      if (freshDraftData) {
        const freshRvpDraftPrograms = freshDraftData.rvpDraftPrograms || [];
  
        // Check if the program name is duplicated in the fresh draft data
        const isDuplicated = freshRvpDraftPrograms.some((program: { program: string; }) => program.program === programName);
  
        if (isDuplicated) {
          // Display a confirmation modal
          Modal.confirm({
            title: 'Duplicate Program Name',
            content: `A program with the name "${programName}" already exists in the draft. Do you still want to copy it?`,
            okText: 'Copy Anyway',
            cancelText: 'Cancel',
            onOk: () => {
              // User confirmed, proceed with copying
              setCopyingToRvpDraftProgram(currentRvpFinalProgram);
              setCopyingConfirmationVisible(true);
            },
          });
        } else {
          // No duplication, proceed with copying directly
          setCopyingToRvpDraftProgram(currentRvpFinalProgram);
          setCopyingConfirmationVisible(true);
        }
      } else {
        notification.error({ message: 'Failed to fetch fresh draft data.' });
      }
    } catch (error) {
      notification.error({ message: 'An error occurred while fetching fresh draft data.' });
    }
  };
  

  // const handleCopyToDraft = async (currentRvpFinalProgram: RVP_Final_Program) => {
  //   setCopyingToRvpDraftProgram(currentRvpFinalProgram);
  //   setCopyingConfirmationVisible(true);
  // };

  const handleRetire = async (currentRvpFinalProgram: RVP_Final_Program) => {
    setRetiring(currentRvpFinalProgram);
    setRetiringConfirmationVisible(true);
  };

  // Function to filter programs by the latest year
  const filterProgramsByLatestYear = (programs: any[]) => {
    if (!showLatestYear) {
      return programs; // If showLatestYear is false, return all programs
    }

    const latestDate = rvpFinalPrograms.reduce((latest:any, program:any) => {
      if (program.fiscal_year_start_date > latest) {
        return program.fiscal_year_start_date;
      }
      return latest;
    }, '1900-01-01'); // Initialize with a very old date
    // Filter programs based on the latest date
    return rvpFinalPrograms.filter((program: any) => program.fiscal_year_start_date === latestDate);
  };

  // Apply the filter to the programs
  const filteredRvpFinalPrograms = filterProgramsByLatestYear(rvpFinalPrograms);

  return (
    <div>
      <Typography.Title level={3}>
        List of Random Verification Programs (Published)
      </Typography.Title>
      <Button onClick={() => setShowLatestYear(!showLatestYear)}>
        {showLatestYear ? 'Show All FY Years' : 'Show Latest FY Year Only'}
      </Button>
      <RvpFinalProgramTable
        rvpFinalPrograms={filteredRvpFinalPrograms}
        onCopyToDraft={handleCopyToDraft}
        onRetire={handleRetire}
        onRead = {showModal}
        onReadStratum={showModalStratum}
      />
      {visibleProgramForm && (
          <RvpFinalProgramFormModal
            visible={visibleProgramForm}
            initialValues={readingRvpFinalProgram as RVP_Final_Program}
            onCancel={() => setVisibleProgramForm(false)}
          />
        )}
       {visibleStratumForm && (
          <RvpFinalStratumFormModal
            visible={visibleStratumForm}
            initialValues={readingRvpFinalStratum as RVP_Final_Stratum}
            onCancel={() => setVisibleStratumForm(false)}
          />
        )} 
      <Modal
          open={copyingConfirmationVisible}
          title="To make a copy to draft table, please enter the following info."
          okText="Copy to draft table"
          cancelText="Cancel"
          onCancel={() => setCopyingConfirmationVisible(false)}
          onOk={confirmCopy}
          maskClosable={false}
        >
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Fiscal Year Start Date:</Typography.Text>
              {'     '}
              <Select
                style={{ width: 240 }}
                options={fisicalYearOptions} 
                onChange={(value) => setInputFiscalYearValue(value)}/>
            </Space>
          </div>
          
        </Modal>
        <Modal
          open={retiringConfirmationVisible}
          title="To retire this program, please enter the following info."
          okText="Retire"
          okButtonProps={{ danger: true }}
          cancelText="Cancel"
          onCancel={() => setRetiringConfirmationVisible(false)}
          onOk={confirmRetire}
          maskClosable={false}
        >
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Reason to retire the program:</Typography.Text>
              {'     '}
              <Input value={inputCommentValue} style={{ width: 250 }} onChange={(e) => setInputCommentValue(e.target.value)} />
            </Space>
          </div>
          
        </Modal>
    </div>
  );

}
export default RvpFinal
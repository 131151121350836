import React, { useState, useEffect } from 'react';
import { Table, Button, DatePicker, message, Typography, Form } from 'antd'; // Import Form component from AntD
import { useLazyQuery, useMutation } from '@apollo/client';
import { START_WELL_POPULATION, CHECK_JOB_STATUS, UPDATE_RVP_DRAFT_STRATUM_POPULATION } from '../../graphql/queries';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

type SamplePopulation = {
  StratumId: string;
  Description: string;
  StratumPopulation: number;
};

const WellPopulation: React.FC = () => {
  const storedPopulations = localStorage.getItem('samplePopulations');
  const initialPopulations = storedPopulations ? JSON.parse(storedPopulations) : [];

  const [samplePopulations, setSamplePopulations] = useState<SamplePopulation[]>(initialPopulations);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cutOffDate, setCutOffDate] = useState<string | null>(() => {
    const storedCutOffDate = localStorage.getItem('cutOffDate');
    return storedCutOffDate ? storedCutOffDate : dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
  });
  const [savedCutOffDate, setSavedCutOffDate] = useState<string | null>(cutOffDate); // Save cutoff date for display purposes
  const [timestamp, setTimestamp] = useState<string | null>(() => {
    const storedTimestamp = localStorage.getItem('timestamp');
    return storedTimestamp ? storedTimestamp : null;
  });
  const [jobId, setJobId] = useState<string | null>(null);
  const [pollingIntervalId, setPollingIntervalId] = useState<NodeJS.Timeout | null>(null);

  const [startWellPopulation] = useMutation(START_WELL_POPULATION);
  const [checkJobStatus, { data: jobStatusData, loading: jobStatusLoading, error: jobStatusError }] = useLazyQuery(CHECK_JOB_STATUS, { pollInterval: 5000 });

  const [updateRvpDraftStratum] = useMutation(UPDATE_RVP_DRAFT_STRATUM_POPULATION);

  // Start job
  const handleStartJob = () => {
    if (cutOffDate && !loading) {
      setError(null);
      setLoading(true);

      // Start the background job
      startWellPopulation({ variables: { cutOffDate } })
        .then(response => {
          const jobId = response.data.startWellPopulation;
          console.log("Job started with jobId:", jobId);
          setJobId(jobId); // Store jobId to begin polling
          startPolling(jobId); // Start polling for job status
        })
        .catch(err => {
          console.error("Error starting the job:", err);
          setError("Failed to start job");
          setLoading(false);
        });
    } else if (!cutOffDate) {
      setError("Please select a cutoff date.");
    }
  };

  // Polling function
  const startPolling = (jobId: string) => {
    if (!pollingIntervalId) {
      console.log("Starting polling...");
      const jobInterval = setInterval(() => {
        console.log("Polling for job status...");
        checkJobStatus({ variables: { jobId } }); // Trigger job status query
      }, 5000); // Poll every 5 seconds
      setPollingIntervalId(jobInterval); // Store interval ID to clear later
    }
  };

  // Stop polling
  const stopPolling = () => {
    if (pollingIntervalId) {
      console.log("Stopping polling...");
      clearInterval(pollingIntervalId);
      setPollingIntervalId(null); // Reset interval ID after clearing
    }
  };

  // Check job status and stop polling if job is completed or failed
  useEffect(() => {
    if (jobStatusData) {
      const status = jobStatusData.checkJobStatus.status;
      console.log("Job status:", status);
      if (status === 'completed') {
        setLoading(false);
        setJobId(null); // Reset jobId once the job is complete
        setTimestamp(dayjs().format('YYYY-MM-DD HH:mm:ss')); // Set timestamp when job finishes
        // Assuming the job result contains the population data
        const populationData = jobStatusData.checkJobStatus.result;
        if (populationData && Array.isArray(populationData)) {
          setSamplePopulations(populationData); // Populate the table data
          // Save to localStorage to persist data across tabs or page reloads
          localStorage.setItem('samplePopulations', JSON.stringify(populationData));
          localStorage.setItem('timestamp', dayjs().format('YYYY-MM-DD HH:mm:ss')); // Save timestamp
          // Update cutOffDate in localStorage when the task is completed
          localStorage.setItem('cutOffDate', cutOffDate || dayjs().format('YYYY-MM-DD'));
          setSavedCutOffDate(cutOffDate); // Update the saved cutOffDate for display
        } else {
          setError("Invalid data format from job.");
        }
        stopPolling(); // Stop polling when the job is completed
      } else if (status === 'failed') {
        setError("Job failed.");
        setLoading(false);
        setJobId(null); // Reset jobId on failure
        stopPolling(); // Stop polling when the job fails
      }
    }
  }, [jobStatusData]); // Only run this effect when jobStatusData changes

  // Handle job status error
  useEffect(() => {
    if (jobStatusError) {
      console.error("Error checking job status:", jobStatusError);
      setError("Error checking job status");
      setLoading(false);
      stopPolling(); // Stop polling in case of an error
    }
  }, [jobStatusError]); // Trigger when there's a job status error

  const handleDateChange = (date: any, dateString: string) => {
    setCutOffDate(dateString); // This only updates the date for future tasks, doesn't immediately affect the displayed cutOffDate
    setError(null);
  };

  const handleUpdatePopulation = () => {
    if (!samplePopulations.length) {
      message.error("No data available to update.");
      return;
    }

    setLoading(true);

    const updatePromises = samplePopulations.map(population =>
      updateRvpDraftStratum({
        variables: {
          stratumId: population.StratumId,
          population: population.StratumPopulation
        },
      })
    );

    Promise.all(updatePromises)
      .then(results => {
        const failedUpdates = results
          .map((result, index) => (!result.data.updateRvpDraftStratumPopulation ? samplePopulations[index].StratumId : null))
          .filter(stratumId => stratumId !== null);
        
        if (failedUpdates.length === 0) {
          message.success("Population updated successfully.");
        } else {
          message.error(`Some updates failed for Stratum IDs: ${failedUpdates.join(', ')}`);
        }
      })
      .catch(err => {
        message.error("Error updating population: " + err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<SamplePopulation> = [
    {
      title: 'Stratum ID',
      dataIndex: 'StratumId',
      key: 'StratumId',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      width: 200,
    },
    {
      title: 'Population',
      dataIndex: 'StratumPopulation',
      key: 'StratumPopulation',
      align: 'right',
      width: 150,
    },
  ];

  return (
    <div>
      <Typography.Title level={4}>RVP Well Population</Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px', width: '1000px' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Form.Item 
            label="Cutoff Date" 
            labelCol={{ span: 8 }} // 8 columns for the label
            wrapperCol={{ span: 16 }} // 16 columns for the DatePicker
            style={{ marginBottom: 0, width: '400px' }} // Adjust width
          >
            <DatePicker 
              onChange={handleDateChange} 
              defaultValue={dayjs(cutOffDate)} // Use the stored cutOffDate or default
              format='YYYY-MM-DD'
              style={{ width: '100%' }} // Ensure the DatePicker fills the space available
            />
          </Form.Item>
          <Button type="primary" onClick={handleStartJob} loading={loading} style={{ marginRight: '8px' }}>
            Calculate Population
          </Button>
          <Button
            type="primary"
            onClick={handleUpdatePopulation}
            disabled={loading || !samplePopulations.length}
            style={{
              backgroundColor: 'orange',
              borderColor: 'orange',
              color: loading || !samplePopulations.length ? 'rgba(0, 0, 0, 0.75)' : 'white',
            }}
          >
            Update Population in Draft Table
          </Button>
          {timestamp && savedCutOffDate && (
            <Typography.Text style={{ marginLeft: '100px' }}>
              Data generated on: {timestamp} (Cutoff Date: {savedCutOffDate})
            </Typography.Text>
          )}
        </div>
      </div>
      {loading && <div>Loading... Please wait for about 5 minutes while the program is running.</div>}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <Table 
          columns={columns} 
          dataSource={samplePopulations} 
          rowKey="StratumId"
          pagination={false} 
          style={{ width: '1000px', marginLeft: 0 }}
        />
      )}
    </div>
  );
};

export default WellPopulation;

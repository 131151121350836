import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import config from "./config";

import {
  PublicClientApplication,
  EventType,
  LogLevel,
  Configuration,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

import response from "./authConfig";

// Function to get a new token using acquireTokenSilent
async function getAccessToken(msalInstance: PublicClientApplication) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const account = accounts[0];

    try {
      // Try to acquire the token silently
      const response = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"], // Specify your required scopes here
        account: account,  // The active account
      });

      // Save or use the access token
      localStorage.setItem(config.tokenKey, JSON.stringify(response.accessToken));
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Silent token acquisition failed (e.g., expired refresh token)
        // Fall back to interactive authentication
        const response = await msalInstance.acquireTokenPopup({
          scopes: ["user.read"], // Specify your required scopes here
        });
        localStorage.setItem(config.tokenKey, JSON.stringify(response.accessToken));
        return response.accessToken;
      } else {
        throw error; // Handle other errors (e.g., network issues)
      }
    }
  }

  throw new Error("No active account found");
}

// Set a timer to refresh the token before it expires (every 55 minutes)
function setTokenRefreshTimer(msalInstance: PublicClientApplication) {
  setInterval(async () => {
    // Get the current access token from localStorage
    const token = JSON.parse(localStorage.getItem(config.tokenKey) || "null");

    if (token) {
      const expiration = token.exp * 1000; // Convert to milliseconds
      const now = Date.now();

      // Check if the token is about to expire in the next 5 minutes
      if (expiration - now < 5 * 60 * 1000) {
        await getAccessToken(msalInstance); // Refresh token silently
      }
    }
  }, 5 * 60 * 1000);  // Check every 5 minutes
}

// Now integrating with your main application
response.then((res) => {
  const msalConfig: Configuration = {
    auth: {
      clientId: res.data.getClientVariables.clientId,
      authority: "https://login.microsoftonline.com/" + res.data.getClientVariables.tenantId,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPII) => {
          console.log(message);
        },
        logLevel: LogLevel.Info,
      },
    },
  };

  const pca = new PublicClientApplication(msalConfig);

  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0]);
  }

  // Handle login success and save tokens
  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      // @ts-ignore
      pca.setActiveAccount(event.payload.account);
      // @ts-ignore
      localStorage.setItem(config.tokenClaimsKey, JSON.stringify(event.payload.account.idTokenClaims));
      // @ts-ignore
      localStorage.setItem(config.rolesKey, JSON.stringify(event.payload.account.idTokenClaims.roles));
      // @ts-ignore
      localStorage.setItem(config.tokenKey, JSON.stringify(event.payload.accessToken));
    }
  });

  // Start the token refresh timer after initialization
  setTokenRefreshTimer(pca);

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App
          msalInstance={pca}
          graphQLEndpoint={res.data.getClientVariables.graphQLEndpoint}
        />
      </BrowserRouter>
    </React.StrictMode>
  );
});

reportWebVitals();

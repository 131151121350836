import React from "react";
import { Layout, Typography } from "antd";
import config from "./config";
import "./App.css";

import SideMenu from "./components/SideMenu";
import AppHeader from "./components/AppHeader";
import AppRoutes from "./components/AppRoutes";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useEffect } from "react";

const { Sider, Content, Footer } = Layout;

const siderStyle: React.CSSProperties = {
  //textAlign: 'center',
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#f0f0f0",
};

const footerStyle: React.CSSProperties = {
  textAlign: "center",
  color: "black",
  backgroundColor: "#f0f0f0",
};

function App(props: {
  msalInstance: PublicClientApplication;
  graphQLEndpoint: string;
}) {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      instance
        .ssoSilent({
          scopes: ["user.read"],
        })
        .then((response) => {
          instance.setActiveAccount(response.account);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect({
              scopes: ["user.read"],
            });
          }
        });
    }
  }, [isAuthenticated, instance]);

  const rolesString: string | null = localStorage.getItem(config.rolesKey);
  const roles: string[] =
    rolesString !== null && rolesString !== "undefined"
      ? JSON.parse(rolesString)
      : [];
  console.log("Roles:", roles);

  if (isAuthenticated && roles.length === 0) {
    return (
      <MsalProvider instance={props.msalInstance}>
        <Layout style={{ minHeight: "100vh" }}>
          <AppHeader />
          <Typography style={footerStyle}>
            It appears that you are not authorized to view the data. If you have
            signed in, try to reload the page.
          </Typography>
          <Footer style={footerStyle}>© 2025 - Alberta Energy Regulator</Footer>
        </Layout>
      </MsalProvider>
    );
  } else {
    return (
      <MsalProvider instance={props.msalInstance}>
        <Layout style={{ minHeight: "100vh" }}>
          <AppHeader />
          <AuthenticatedTemplate>
            <Layout>
              <Sider style={siderStyle}>
                <SideMenu />
              </Sider>
              <Content style={{ padding: "10px" }}>
                <div>
                  <AppRoutes graphQLEndpoint={props.graphQLEndpoint} />
                </div>
              </Content>
            </Layout>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Typography style={footerStyle}>
              Please sign-in to see the data for your application.
            </Typography>
          </UnauthenticatedTemplate>
          <Footer style={footerStyle}>© 2025 - Alberta Energy Regulator</Footer>
        </Layout>
      </MsalProvider>
    );
  }
}

export default App;
